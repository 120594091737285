import { Button, Dialog, DialogContent, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import errIcon from "../../assets/icons/err-noti.svg";
import warningIcon from "../../assets/icons/warning-noti.svg";
import { formatMoney } from "../../extra/utils";

function WarningOrder({ state, dispatch, isMobile }) {
  const { dataStep1, dataStep2 } = state;
  const classes = useStyles();

  return (
    <Dialog
      open={!!dataStep2?.warning?.open || !!dataStep1?.warning?.open}
      keepMounted
      className={
        dataStep1?.warning?.type === "warningFeeFromProvider"
          ? classes.fullWidth
          : classes.warning
      }
    >
      <DialogContent className={classes.content}>
        {dataStep1?.warning?.type === "isWithinAgeDepends" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div className="mt-15">
              Thông báo người được bảo hiểm <b>{dataStep1?.warning?.data}</b>{" "}
              phải mua cùng chủ hợp đồng. Vui lòng thêm chủ hợp đồng vào danh
              sách người được bảo hiểm.
            </div>

            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarningStep1")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => {
                  dispatch("closeWarningStep1");
                  dispatch({
                    type: "checkBuyMyself",
                    value: true,
                  });
                }}
              >
                Thêm
              </Button>
            </div>
          </>
        )}
        {dataStep1?.warning?.type === "warningFeeFromProvider" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div className="title">
              Thông báo thay đổi phí của người được bảo hiểm:
            </div>
            {isMobile ? (
              <>
                {(dataStep1?.warning?.data?.data || []).map((item, index) => (
                  <div key={index} style={{ width: "100%" }}>
                    <div className="row-info">
                      <div>Người được bảo hiểm</div>
                      <div className="value-info capitalize">
                        {item?.fullname || ""}
                      </div>
                    </div>
                    <div className="row-info">
                      <div>Phí trước khi giảm / tăng</div>
                      <div className="value-info">
                        {formatMoney(
                          dataStep1.insuredPerson?.[index]?.feesPaymentFromGC
                        ) || 0}{" "}
                        VNĐ
                      </div>
                    </div>
                    <div className="row-info">
                      <div>Phí giảm / tăng</div>
                      <div
                        className={
                          (item?.fees || 0) -
                            (dataStep1.insuredPerson?.[index]
                              ?.feesPaymentFromGC || 0) >
                          0
                            ? "red-txt value-info"
                            : "green-txt value-info"
                        }
                      >
                        {(item?.fees || 0) -
                          (dataStep1.insuredPerson?.[index]
                            ?.feesPaymentFromGC || 0) >
                        0
                          ? "+"
                          : ""}
                        {formatMoney(
                          (item?.fees || 0) -
                            (dataStep1.insuredPerson?.[index]
                              ?.feesPaymentFromGC || 0)
                        ) || 0}{" "}
                        VNĐ
                      </div>
                    </div>
                    <div className="row-info">
                      <div>Phí sau khi giảm / tăng</div>
                      <div
                        className={
                          (item?.fees || 0) -
                            (dataStep1.insuredPerson?.[index]
                              ?.feesPaymentFromGC || 0) >
                          0
                            ? "red-txt value-info"
                            : "green-txt value-info"
                        }
                      >
                        {formatMoney(item?.fees) || 0} VNĐ
                      </div>
                    </div>
                    <div className="reason">
                      Lý do: {item?.adjustmentReason || ""}
                    </div>
                    <Divider />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="header-table">
                  <div className="w-25">Người được bảo hiểm</div>
                  <div className="w-25">Phí trước khi giảm / tăng</div>
                  <div className="w-25">Phí giảm / tăng</div>
                  <div className="w-25">Phí sau khi giảm / tăng</div>
                </div>
                <Divider style={{ margin: "5px 0 12px" }} />
                {(dataStep1?.warning?.data?.data || []).map((item, index) => (
                  <div key={index} style={{ width: "100%" }}>
                    <div className="row-table">
                      <div className="bold-text w-25">
                        {formatMoney(item?.fullname) || ""}
                      </div>
                      <div className="bold-text w-25">
                        {formatMoney(
                          dataStep1.insuredPerson?.[index]?.feesPaymentFromGC
                        ) || 0}{" "}
                        VNĐ
                      </div>
                      <div
                        className={
                          (item?.fees || 0) -
                            (dataStep1.insuredPerson?.[index]
                              ?.feesPaymentFromGC || 0) >
                          0
                            ? "red-txt w-25"
                            : "green-txt w-25"
                        }
                      >
                        {(item?.fees || 0) -
                          (dataStep1.insuredPerson?.[index]
                            ?.feesPaymentFromGC || 0) >
                        0
                          ? "+"
                          : ""}
                        {formatMoney(
                          (item?.fees || 0) -
                            (dataStep1.insuredPerson?.[index]
                              ?.feesPaymentFromGC || 0)
                        ) || 0}{" "}
                        VNĐ
                      </div>
                      <div
                        className={
                          (item?.fees || 0) -
                            (dataStep1.insuredPerson?.[index]
                              ?.feesPaymentFromGC || 0) >
                          0
                            ? "red-txt w-25"
                            : "green-txt w-25"
                        }
                      >
                        {formatMoney(item?.fees) || 0} VNĐ
                      </div>
                    </div>
                    <div className="reason">
                      Lý do: {item?.adjustmentReason || ""}
                    </div>
                  </div>
                ))}
              </>
            )}

            <Divider />
            <div className="row">
              <div className="color-text2">
                Phí bảo hiểm sau thay đổi cần phải thanh toán:
              </div>
              <div className="color-text1">
                {formatMoney(
                  dataStep1?.warning?.data?.paymentFeesFromProvider
                ) || 0}{" "}
                VNĐ
              </div>
            </div>
            <Divider style={{ marginTop: 10 }} />

            <div className="confirm">
              Bạn có muốn tiếp tục mua bảo hiểm không?
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarningStep1")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => dispatch("acceptChangeFee")}
              >
                Đồng ý
              </Button>
            </div>
          </>
        )}
        {dataStep2?.warning?.type === "warningInsuredPerson" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div className="mt-15">
              <div>
                Thông báo người bảo hiểm sau không được tham gia gói bảo hiểm
                này:
              </div>
              {(dataStep2?.warning?.errPerson || []).map((p, idx) => (
                <div className="list">
                  {idx + 1}. {p.insuredName}
                </div>
              ))}
            </div>
            <div className="confirm">
              Bạn có muốn bỏ ra khỏi danh sách này không?
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarningStep2")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => dispatch("acceptRemovalOfPerson")}
              >
                Đồng ý
              </Button>
            </div>
          </>
        )}
        {dataStep2?.warning?.type === "warningPackage" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div className="mt-15 center">
              <div>
                Rất tiếc, độ tuổi của{" "}
                {dataStep2?.warning?.errPerson?.[0]?.insuredName || ""} không
                được tham gia gói bảo hiểm này.
              </div>
            </div>
            <div className="confirm">
              Bạn có muốn bỏ{" "}
              {dataStep2?.warning?.errPerson?.[0]?.insuredName || ""} ra không?
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarningStep2")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() =>
                  dispatch({ type: "acceptRemovalOfPerson", from: "step1" })
                }
              >
                Đồng ý
              </Button>
            </div>
          </>
        )}
        {/* {dataStep2?.warning?.type === "warningBenefit" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div className="mt-15">
              {`Rất tiếc, ${
                dataStep1?.buyerName || ""
              } không được tham gia quyền lợi ${
                dataStep2?.warning?.missingBenefitTitle
              }`}
            </div>
            <div className="confirm">
              Bạn có muốn bỏ quyền lợi này ra không?
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarningStep2")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => dispatch("acceptRemovalOfBenefit")}
              >
                Đồng ý
              </Button>
            </div>
          </>
        )} */}
        {dataStep2?.warning?.type === "error" && (
          <>
            <img src={errIcon} alt="noti-icon" />
            <div className="mt-15 center">
              <div>
                Rất tiếc, người được bảo hiểm{" "}
                <b style={{ textTransform: "capitalize" }}>
                  {dataStep2?.warning?.errPerson?.[0]?.insuredName || ""}
                </b>{" "}
                không được tham gia gói bảo hiểm này
              </div>
            </div>
            {/* <div className="confirm">
              Bạn có muốn bỏ ra khỏi danh sách này không?
            </div> */}
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarningStep2")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => dispatch("closeOrderDialog")}
              >
                Trở về trang chủ
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default WarningOrder;

const useStyles = makeStyles((theme) => ({
  warning: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: 5,
      width: "90%",
      maxWidth: "500px",
      margin: 0,
    },
  },
  fullWidth: {
    "& .MuiDialogContent-root": {
      [theme.breakpoints.down("sm")]: {
        padding: "10px 10px 20px 10px",
      },
    },
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: 24,
      maxWidth: "90%",
      margin: 0,
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.4,
    "& .mt-15": {
      marginTop: "15px",
    },
    "& .center": {
      textAlign: "center",
    },
    "& .list": {
      marginTop: 5,
      textTransform: "capitalize",
    },
    "& .confirm": {
      textAlign: "center",
      fontWeight: "bold",
      marginTop: 15,
    },
    "& .reason": {
      width: "100%",
      color: "#FB7800",
      margin: "8px 0px",
      fontStyle: "italic",
      fontSize: 14,
      textAlign: "left",
    },
    "& .color-text1": {
      color: "#FB7800",
      fontSize: 18,
      whiteSpace: "nowrap",
      marginLeft: 15,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .color-text2": {
      color: "#1A427D",
      fontSize: 18,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .row": {
      width: "100%",
      marginTop: "10px",
      fontWeight: 600,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },

    "& .title": {
      textAlign: "center",
      marginTop: "15px",
    },
    "& .header-table": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "10px",
      gap: 30,
      fontSize: 14,
    },

    "& .row-table": {
      width: "100%",
      fontSize: 14,
      background: "#F0F2F5",
      borderRadius: 20,
      fontWeight: 600,

      padding: "6px 0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },

    "& .w-25": {
      width: "25%",
      whiteSpace: "nowrap",
      marginRight: 30,
      textAlign: "center",
    },
    "& .bold-text": {
      color: "#343A40",
      textTransform: "capitalize",
    },
    "& .green-txt": {
      color: "#39B44A",
    },
    "& .red-txt": {
      color: "#FD1D1D",
    },

    "& .row-info": {
      fontSize: 14,
      marginTop: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .value-info": {
      width: "42%",
      fontWeight: 600,
      background: "#F0F2F5",
      borderRadius: 20,
      padding: "5px 8px",
      textAlign: "center",
    },

    "& .action": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 15,
    },
    "& .btn": {
      padding: "6px 15px",
      borderRadius: 6,
      textTransform: "none",
      fontSize: 14,
      fontWeight: 500,
      color: "#fff",
      background: "#0B7CE4",
      border: "1px solid #0B7CE4",
      "&:hover": {
        color: "#fff",
        background: "#0B7CE4",
      },
    },
    "& .btn-outlined": {
      padding: "6px 15px",
      marginRight: 15,
      borderRadius: 6,
      border: "1px solid #3961A0",
      background: "#fff",
      color: "#3961A0",
      textTransform: "none",
      fontSize: 14,
      boxShadow: "none",
    },

    "& .capitalize": {
      textTransform: "capitalize",
    },
    "& .MuiDivider-root": {
      width: "100%",
    },
  },
}));
