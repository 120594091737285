import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Radio,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import moment from "moment";
import React, { useCallback } from "react";
import { formatDateHour, formatMoney } from "../../extra/utils";

import checked from "../../assets/icons/checked.svg";
import voucher from "../../assets/icons/voucher.svg";
import example from "../../assets/images/example.png";

function VoucherDialog({ data, dispatch }) {
  const classes = useStyles();
  const debouncedGetVoucherList = useCallback(
    _.debounce((value) => {
      dispatch({ type: "getVoucherList", value });
    }, 800),
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!data?.openVoucher}
      className={classes.voucher}
    >
      <div className="content">
        <div className="row header">
          <div></div>
          <div className="mx-50">Chọn voucher</div>
          <CloseIcon onClick={() => dispatch("closeVoucher")} />
        </div>
        <Divider />
        <div className="row">
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={data?.redeemCode || ""}
            placeholder="Nhập mã ưu đãi"
            onChange={(e) => {
              dispatch({
                type: "onChangeRedeemCode",
                value: e.target.value,
              });
              debouncedGetVoucherList(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <img src={voucher} alt="voucher" style={{ marginRight: 8 }} />
              ),
              endAdornment: data?.redeemCode ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch({
                      type: "onChangeRedeemCode",
                      value: "",
                    });
                    debouncedGetVoucherList();
                  }}
                >
                  <ClearIcon style={{ fontSize: 15 }} />
                </IconButton>
              ) : null,
            }}
          />
          {/* <Button
            className="apply btn"
            disabled={!data?.redeemCode}
            onClick={() => dispatch("")}
          >
            Tìm kiếm
          </Button> */}
        </div>
        {_.isEmpty(data?.arrVoucher) ? (
          <div className="empty"></div>
        ) : (
          <div>
            <div>Có thể chọn 1 Voucher</div>
            {data.arrVoucher.map((item, index) => (
              <div key={`voucher-${index}`} className="boxItem row">
                <div className="row">
                  <img
                    src={item?.img || example}
                    alt="img-voucher"
                    className="img"
                  />
                  <div className="info">
                    <div className="discount">
                      Giảm {formatMoney(item?.value)}
                      {item?.type === "phan_tram" ? "%" : "đ"}
                    </div>
                    <div className="date">
                      Hết hạn ngày{" "}
                      {item?.endDate
                        ? moment(item?.endDate).format("DD.MM.YYYY")
                        : ""}{" "}
                    </div>
                    <div
                      className="link"
                      onClick={() => dispatch({ type: "showCondition", item })}
                    >
                      Điều kiện
                    </div>
                  </div>
                </div>
                <Radio
                  checked={data?.selectedVoucher?.uid === item?.uid}
                  onClick={() => dispatch({ type: "selectVoucher", item })}
                />
              </div>
            ))}
          </div>
        )}
        <div className="action">
          <Button
            className="btn w-100"
            disabled={_.isEmpty(data?.selectedVoucher)}
            onClick={() => dispatch("applyVoucher")}
          >
            Áp dụng
          </Button>
        </div>
      </div>

      <Dialog
        open={data?.conditionOpen}
        onClose={() => dispatch("closeCondition")}
        maxWidth="xs"
        fullWidth
        className={classes.condition}
      >
        <div>
          <div className="row header">
            <div></div>
            <div className="mx-50">ĐIỀU KIỆN ÁP DỤNG</div>
            <CloseIcon onClick={() => dispatch("closeCondition")} />
          </div>
          <div className="content">
            <div className="row align-start">
              <img src={checked} alt="checked" className="icon" />
              <div>
                <div className="text-bold">Đối tượng</div>
                <div className="text-14">
                  {data?.conditionVoucher?.conditions?.targetObject ||
                    "Tất cả đối tượng"}
                </div>
              </div>
            </div>
            <div className="row align-start">
              <img src={checked} alt="checked" className="icon" />
              <div>
                <div className="text-bold">Khu vực</div>
                <div className="text-14">
                  {data?.conditionVoucher?.region || "Tất cả khu vực"}
                </div>
              </div>
            </div>
            <div className="row align-start">
              <img src={checked} alt="checked" className="icon" />
              <div>
                <div className="text-bold">Thời gian</div>
                <div className="text-14">
                  {formatDateHour(
                    data?.conditionVoucher?.conditions?.beginDate
                  )}{" "}
                  -{" "}
                  {formatDateHour(data?.conditionVoucher?.conditions?.endDate)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
}

export default VoucherDialog;

const useStyles = makeStyles((theme) => ({
  voucher: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },

    "& .MuiDialog-paper": {
      maxWidth: "500px",
      borderRadius: 16,
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        minWidth: "95vw",
      },
    },
    "& .content": {
      display: "grid",
      gap: 15,
      padding: 25,
      [theme.breakpoints.down("sm")]: {
        padding: "20px 15px",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .header": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 20,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .mx-50": { margin: "0 50px" },
    "& .empty": {
      height: 100,
    },
    "& .apply": {
      width: "30%",
      minWidth: "fit-content",
      marginLeft: 8,
    },
    "& .boxItem": {
      border: "1px solid #C0C0C0",
      borderRadius: 5,
      marginTop: 15,
      paddingRight: 10,
    },
    "& .img": {
      height: "85px",
    },
    "& .info": {
      padding: "10px 15px",
      lineHeight: 1.4,
      color: "#343A40",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 0px 10px 10px",
      },
    },
    "& .discount": {
      fontWeight: 600,
      fontSize: 18,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .link": {
      color: "#0044CC",
      textDecoration: "none",
      fontSize: 14,
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },

    "& .action": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 10,
    },
    "& .w-100": {
      width: "100%",
    },
    "& .btn": {
      padding: "6px 12px",
      borderRadius: 4,
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
      color: "#fff",
      background: "#1A427D",
      border: "1px solid #1A427D",
      [theme.breakpoints.down("sm")]: {
        padding: "4px 10px",
      },
      "&:hover": {
        color: "#fff",
        background: "#1A427D",
      },
    },

    "& .Mui-disabled": {
      background: "#f1f1f1",
      border: "1px solid #f1f1f1",
    },

    "& .err": {
      color: "red",
      fontSize: 13,
      marginTop: "4px",
    },
    "& .MuiOutlinedInput-input": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .MuiRadio-root": {
      padding: 0,
    },
  },
  condition: {
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .header": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 20,
      padding: "15px 20px",

      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .content": {
      padding: 20,
    },
    "& .align-start": {
      marginBottom: 12,
      alignItems: "flex-start",
    },
    "& .icon": {
      marginTop: 2,
      marginRight: 8,
    },
    "& .text-bold": {
      fontWeight: 500,
      marginBottom: 4,
    },
    "& .text-14": {
      fontSize: 14,
    },
  },
}));
