import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion";
import React from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

const animateRight = {
  initial: { x: 50, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: -50, opacity: 0 },
  transition: { duration: 0.5 },
};

function Banner({ isMobile, banners }) {
  const classes = useStyles();

  return (
    <div>
      <motion.div key="swiper-1" {...animateRight}>
        <div className={classes.swiper}>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {banners.map((banner, index) => (
              <SwiperSlide key={index}>
                <img
                  src={isMobile ? banner.mobile_url : banner.desktop_url}
                  alt={`banner-${index}`}
                  className="banner"
                ></img>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </motion.div>
    </div>
  );
}

export default Banner;

const useStyles = makeStyles((theme) => ({
  swiper: {
    "& .banner": {
      width: "100%",
      cursor: "pointer",
      borderRadius: 24,
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    "& .swiper-pagination-bullet-active": {
      background: "#fff",
    },
  },
}));
