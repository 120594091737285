import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

import {
  Autocomplete,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  MenuItem,
  Popover,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Base64 } from "js-base64";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useReducer from "../../library/hooks/useReducer";
import Booking from "../components/bhSKhoe/Booking";
import DetailDialog from "../components/bhSKhoe/DetailDialog";
import ErrReports from "../components/bhSKhoe/ErrReports";
import InfoDialog from "../components/bhSKhoe/InfoDialog";
import OTPVerify from "../components/bhSKhoe/OTPVerify";
import OrderDialog from "../components/bhSKhoe/OrderDialog";
import ReferalInfo from "../components/bhSKhoe/ReferalInfo";
import SuccessDialog from "../components/bhSKhoe/SuccessDialog";

import {
  BpCheckedIcon,
  BpIcon,
  genders,
  hospitalGuaranteeName,
  initialState,
  orderByArr,
  searchFees,
} from "../extra/hard-data";
import {
  createEventGtag,
  formatDiscountCurrency,
  formatMoney,
  getParamUrl,
} from "../extra/utils";
import bhSKhoeReducer from "../reducers/bhSKhoeReducer";
import { useStyles } from "../styles/bhskStyles";

import cart from "../assets/icons/cart.svg";
import empty from "../assets/icons/empty.svg";
import logoDefault from "../assets/icons/logo_default.svg";
import reportErr from "../assets/icons/report-err.svg";
import share from "../assets/icons/share.svg";
import showMenu from "../assets/icons/show-menu.svg";
import booking from "../assets/images/booking.png";
import infoIcon from "../assets/icons/info-icon.svg";

function BHSucKhoe({ isMobile, productSelected }) {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const inputProviderRef = useRef(null);
  const inputAgeRef = useRef(null);

  const [state, dispatch] = useReducer({
    reducer: bhSKhoeReducer,
    name: "bhSKhoeReducer",
    initialState,
    navigate,
    location,
    productSelected,
  });

  useEffect(() => {
    dispatch("initial");
  }, []);

  const {
    providers,
    ageArr,
    dataSearch,
    data,
    dataBooking,
    dataInfo,
    dataReferal,
    dataErrReport,
    openDetail,
    openOrder,
    openOTPVerify,
    openSuccess,
    loading,
    expanding,
    page,
    errors,
  } = state;

  useEffect(() => {
    const benefitsDetailUidList = getParamUrl("benefitsDetailUidList");
    const infoFilter = getParamUrl("infoFilter");
    const gender = getParamUrl("gender");

    const handleDetail = async () => {
      await dispatch({
        type: "getDetail",
        benefitsDetailUidList,
        infoFilter,
        gender,
      });
      dispatch("openDetailDialog");
    };

    if (benefitsDetailUidList) {
      handleDetail();
    }
  }, [location?.search]);

  return (
    <>
      <div className={classes.search}>
        <div className="searchBox">
          {/* {!isMobile ? (
            <div id="benefit" style={{ display: "flex" }}>
              <div className="label">Quyền lợi BH:</div>
              <FormGroup row key={_.uniqueId()}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes("noi_tru")}
                      name="noi_tru"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Nội trú"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes(
                        "ngoai_tru"
                      )}
                      name="ngoai_tru"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Ngoại trú"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes("rang")}
                      name="rang"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Răng"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes(
                        "thai_san"
                      )}
                      name="thai_san"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Thai sản"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes(
                        "tu_vong_thuong_tat_tai_nan"
                      )}
                      name="tu_vong_thuong_tat_tai_nan"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Tử vong, thương tật vĩnh viễn do tai nạn"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes(
                        "tu_vong_thuong_tat_benh_tat_thai_san"
                      )}
                      name="tu_vong_thuong_tat_benh_tat_thai_san"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Tử vong, thương tật vĩnh viễn do bệnh tật, thai sản"
                />
              </FormGroup>
            </div>
          ) : (
            <Grid id="benefit" container>
              <Grid xs={12} sm={12} md={2}>
                <div className="label">Quyền lợi BH:</div>
              </Grid>
              <Grid xs={6} sm={6} md={1.5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes("noi_tru")}
                      name="noi_tru"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Nội trú"
                />
              </Grid>
              <Grid xs={6} sm={6} md={1.5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes(
                        "ngoai_tru"
                      )}
                      name="ngoai_tru"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Ngoại trú"
                />
              </Grid>
              <Grid xs={6} sm={6} md={1.5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes("rang")}
                      name="rang"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Răng"
                />
              </Grid>
              <Grid xs={6} sm={6} md={1.5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes(
                        "thai_san"
                      )}
                      name="thai_san"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Thai sản"
                />
              </Grid>
              <Grid xs={12} sm={12} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes(
                        "tu_vong_thuong_tat_tai_nan"
                      )}
                      name="tu_vong_thuong_tat_tai_nan"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Tử vong, thương tật vĩnh viễn do tai nạn"
                />
              </Grid>
              <Grid xs={12} sm={12} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(dataSearch?.category || []).includes(
                        "tu_vong_thuong_tat_benh_tat_thai_san"
                      )}
                      name="tu_vong_thuong_tat_benh_tat_thai_san"
                      onChange={(e) => dispatch({ type: "handleCheck", e })}
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Tử vong, thương tật vĩnh viễn do bệnh tật, thai sản"
                />
              </Grid>
            </Grid>
          )} */}

          <Grid container spacing={2} className="mt-10">
            <Grid xs={12} sm={12} md={3}>
              <Autocomplete
                options={providers}
                getOptionLabel={(option) => option?.title || ""}
                size="small"
                value={dataSearch?.provider || null}
                inputValue={dataSearch?.inputValueProvider || ""}
                onInputChange={(e, newValue) =>
                  dispatch({
                    type: "onChangeSearch",
                    name: "inputValueProvider",
                    value: newValue,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={inputProviderRef}
                    placeholder="Nhà bảo hiểm"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        const matchingOption = providers.find((option) =>
                          option.title
                            .toLowerCase()
                            .includes(
                              dataSearch?.inputValueProvider.toLowerCase()
                            )
                        );

                        if (matchingOption) {
                          dispatch({
                            type: "onChangeSearch",
                            name: "provider",
                            value: matchingOption,
                          });
                          inputProviderRef.current?.blur();
                        }
                      }
                    }}
                  />
                )}
                onChange={(e, newValue) =>
                  dispatch({
                    type: "onChangeSearch",
                    name: "provider",
                    value: newValue,
                  })
                }
                className={!dataSearch?.provider ? "age" : ""}
                classes={{ option: classes.option }}
                openOnFocus
                clearOnEscape
              />
            </Grid>
            <Grid xs={12} sm={12} md={3}>
              <TextField
                select
                label="Phí bảo hiểm"
                value={dataSearch?.fees || ""}
                size="small"
                fullWidth
                onChange={(e) =>
                  dispatch({
                    type: "onChangeSearch",
                    name: "fees",
                    value: e.target.value,
                  })
                }
              >
                {searchFees.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    className={classes.option}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} sm={12} md={3}>
              <Autocomplete
                options={ageArr}
                getOptionLabel={(option) => option?.title || ""}
                size="small"
                value={dataSearch?.age || null}
                inputValue={dataSearch?.inputValueAge || ""}
                onInputChange={(e, newValue) =>
                  dispatch({
                    type: "onChangeSearch",
                    name: "inputValueAge",
                    value: newValue,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={inputAgeRef}
                    placeholder="Chọn độ tuổi"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        const matchingOption = ageArr.find((option) =>
                          option.title
                            .toLowerCase()
                            .includes(dataSearch?.inputValueAge.toLowerCase())
                        );
                        if (matchingOption) {
                          dispatch({
                            type: "onChangeSearch",
                            name: "age",
                            value: matchingOption,
                          });
                          inputAgeRef.current?.blur();
                        }
                      }
                    }}
                  />
                )}
                onChange={(e, newValue) =>
                  dispatch({
                    type: "onChangeSearch",
                    name: "age",
                    value: newValue,
                  })
                }
                className={
                  !dataSearch?.age
                    ? `age ${errors.includes("ageFilter") ? "age-err" : ""}`
                    : ""
                }
                classes={{ option: classes.option }}
                openOnFocus
                clearOnEscape
              />
              {errors.includes("ageFilter") && (
                <div className="err">Vui lòng chọn độ tuổi</div>
              )}
            </Grid>
            <Grid xs={6} sm={6} md={1.5}>
              <Button
                variant="contained"
                className="btn-search"
                onClick={() => {
                  dispatch("getList");
                  createEventGtag("health_home_search_btn");
                }}
              >
                Tìm kiếm
              </Button>
            </Grid>
            <Grid xs={6} sm={6} md={1.5}>
              <Button
                className="btn-outline"
                onClick={() => {
                  dispatch("resetFilter");
                  dispatch("getList");
                }}
              >
                Xoá bộ lọc
              </Button>
            </Grid>
          </Grid>
        </div>

        {!isMobile ? (
          <div className="filter-data">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="nowrap">Sắp xếp theo:</div>
              <TextField
                select
                value={dataSearch?.orderBy || 2}
                size="small"
                fullWidth
                onChange={(e) => {
                  dispatch({
                    type: "onChangeSearch",
                    name: "orderBy",
                    value: e.target.value,
                  });
                  dispatch("getList");
                  createEventGtag("health_home_filter_sort_list");
                }}
                style={{ minWidth: 180 }}
              >
                {orderByArr.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    className={classes.option}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <div className="line"></div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      dispatch({
                        type: "onChangeSearch",
                        name: "discount",
                        value: e.target.checked,
                      });
                      dispatch("getList");
                      createEventGtag("health_home_filter_discount");
                    }}
                    icon={<BpIcon />}
                    checkedIcon={<BpCheckedIcon />}
                  />
                }
                label="Giảm giá"
                className="nowrap"
              />
              <div className="line"></div>
              <div className="nowrap">Giới tính:</div>
              <TextField
                select
                value={dataSearch?.gender || 3}
                size="small"
                fullWidth
                onChange={(e) => {
                  dispatch({
                    type: "onChangeSearch",
                    name: "gender",
                    value: e.target.value,
                  });
                  dispatch("getList");
                  createEventGtag("health_home_filter_gender");
                }}
              >
                <MenuItem value={3} className={classes.option}>
                  Tất cả
                </MenuItem>
                {genders.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    className={classes.option}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div style={{ textAlign: isMobile ? "start" : "end" }}>
              Có <b style={{ color: "#1A427D" }}>{page?.total}</b> sản phẩm được
              tìm thấy
            </div>
          </div>
        ) : (
          <Grid container spacing={2} className="my-15">
            <Grid xs={12} sm={12} md={4} className="row">
              <div className="nowrap">Sắp xếp theo:</div>
              <TextField
                select
                value={dataSearch?.orderBy || 2}
                size="small"
                onChange={(e) => {
                  dispatch({
                    type: "onChangeSearch",
                    name: "orderBy",
                    value: e.target.value,
                  });
                  dispatch("getList");
                  createEventGtag("health_home_filter_sort_list");
                }}
              >
                {orderByArr.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    className={classes.option}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <div className="line hiden"></div>
            </Grid>
            <Grid xs={5} sm={5} md={2} className="row">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      dispatch({
                        type: "onChangeSearch",
                        name: "discount",
                        value: e.target.checked,
                      });
                      dispatch("getList");
                      createEventGtag("health_home_filter_discount");
                    }}
                    icon={<BpIcon />}
                    checkedIcon={<BpCheckedIcon />}
                  />
                }
                label="Giảm giá"
                style={{
                  whiteSpace: "nowrap",
                }}
              />
              <div className="line" style={{ margin: 0 }}></div>
            </Grid>
            <Grid xs={7} sm={7} md={2} className="row">
              <div className="nowrap">Giới tính:</div>
              <TextField
                select
                value={dataSearch?.gender || 3}
                size="small"
                fullWidth
                onChange={(e) => {
                  dispatch({
                    type: "onChangeSearch",
                    name: "gender",
                    value: e.target.value,
                  });
                  dispatch("getList");
                  createEventGtag("health_home_filter_gender");
                }}
              >
                <MenuItem value={3} className={classes.option}>
                  Tất cả
                </MenuItem>
                {genders.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    className={classes.option}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} sm={12} md={4} alignContent={"center"}>
              <div style={{ textAlign: isMobile ? "start" : "end" }}>
                Có <b style={{ color: "#1A427D" }}>{page?.total}</b> sản phẩm
                được tìm thấy
              </div>
            </Grid>
          </Grid>
        )}

        {loading && (
          <div className="loading">
            <CircularProgress size={25} style={{ color: "#1A427D" }} />
            <div className="loading-text">Đang tải BH...</div>
          </div>
        )}

        {!dataSearch?.hasAge && !loading && (
          <div className="empty-filter">
            Vui lòng chọn độ tuổi để tìm kiếm sản phẩm
          </div>
        )}

        {_.isEmpty(data) && dataSearch?.hasAge ? (
          <div className="empty">
            <img src={empty} alt="empty" />
          </div>
        ) : (
          <>
            <Grid container spacing={2} style={{ margin: "20px 0 30px" }}>
              {data.map((i, index) => (
                <ProductItem
                  item={i}
                  index={index}
                  dispatch={dispatch}
                  classes={classes}
                />
              ))}
            </Grid>
            {page.total > data.length && (
              <div>
                {expanding ? (
                  <div className="expanding">
                    <CircularProgress
                      size={16}
                      style={{ color: "#1A427D", marginRight: 8 }}
                    />
                    Đang tải...
                  </div>
                ) : (
                  <div
                    className="expand-more"
                    onClick={() => {
                      dispatch("changePage");
                      createEventGtag("health_home_expand_more_click");
                    }}
                  >
                    Xem thêm <ExpandMoreIcon />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {openDetail && (
        <DetailDialog state={state} dispatch={dispatch} isMobile={isMobile} />
      )}
      {openOrder && (
        <OrderDialog state={state} dispatch={dispatch} isMobile={isMobile} />
      )}
      {openOTPVerify && (
        <OTPVerify isMobile={isMobile} state={state} dispatch={dispatch} />
      )}
      {openSuccess && <SuccessDialog state={state} dispatch={dispatch} />}

      {!!dataBooking?.open && <Booking state={state} dispatch={dispatch} />}

      {!!dataInfo?.open && (
        <InfoDialog dataInfo={dataInfo} dispatch={dispatch} />
      )}
      {!!dataReferal?.open && (
        <ReferalInfo dataReferal={dataReferal} dispatch={dispatch} />
      )}

      {!!dataErrReport?.open && (
        <ErrReports dataErrReport={dataErrReport} dispatch={dispatch} />
      )}

      <img
        src={booking}
        alt="booking-img"
        className={classes.bookingImg}
        onClick={() => dispatch("openBooking")}
      />
    </>
  );
}

export default BHSucKhoe;

const ProductItem = ({ item, index, dispatch, classes }) => {
  const divRef = useRef(null);
  const divProminentRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "excluded-region-popover" : undefined;

  const [anchorElProminent, setAnchorElProminent] = useState(null);
  const openProminent = Boolean(anchorElProminent);
  const idProminent = openProminent ? "prominent-popover" : undefined;

  return (
    <Grid xs={12} sm={12} md={4} lg={3} key={index} className={classes.boxItem}>
      <div className="box">
        <div>
          <div className="title-box">
            <div className="logo">
              <img
                src={item?.providerLogo || logoDefault}
                alt="logo"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
            <div className={item?.discount ? "mr-discount" : "mr-10"}>
              <div className="title">{item?.productTitle || ""}</div>
              <div className="gray">{item?.providerTitle || ""}</div>
            </div>
            <div className="share">
              <img
                src={showMenu}
                alt="show-menu"
                onClick={() => dispatch({ type: "openShare", index })}
              />
            </div>
            {item?.discount && (
              <div className="discount">
                Giảm{" "}
                {item?.discount > 100
                  ? formatDiscountCurrency(item?.discount)
                  : `${item?.discount}%`}
              </div>
            )}
          </div>
          <div className="line"></div>
          <div className="bg-box">
            <div className="title-1">{item?.packagesTitle || ""}</div>
            <div className="fee">
              {/* fees is fee before discount */}
              {formatMoney(
                item?.feesPayment ? item?.feesPayment : item?.fees
              ) || 0}{" "}
              VNĐ
            </div>
            {item?.feesPayment && (
              <div className="discount-fee">
                Giá gốc: {formatMoney(item?.fees) || 0}
                {" VNĐ"}
              </div>
            )}
          </div>
          <div className="line"></div>
          <div className="info">
            <div className="row">
              <div>Giới tính:</div>
              <div className="value">
                {genders.find((i) => i.id === item?.gender)?.label || "N/A"}
              </div>
            </div>
            <div className="row">
              <div>Độ tuổi</div>
              <div className="value">
                {item?.age?.type === "day"
                  ? "Dưới 1 tuổi"
                  : `${item?.age?.value || ""} tuổi`}
              </div>
            </div>
            <div className="row">
              <div>Phạm vi bảo hiểm:</div>
              <div className="value">{item?.regions?.name || "N/A"}</div>
            </div>
            <div className="data-row">
              <div style={{ whiteSpace: "nowrap" }}>Khu vực loại trừ:</div>
              <div className="row">
                <div
                  ref={divRef}
                  title={item?.excludedRegion || ""}
                  className="value-2 ellipsis"
                >
                  {item?.excludedRegion || "N/A"}
                </div>
                {divRef?.current?.offsetWidth > 120 && (
                  <>
                    <img
                      src={infoIcon}
                      alt="infoIcon"
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                    />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div
                        style={{ padding: 10, fontSize: 14, maxWidth: "200px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontWeight: 500, color: "#646D69" }}>
                            Khu vực loại trừ
                          </div>
                          <CloseIcon
                            style={{ width: 18, color: "#646D69" }}
                            onClick={() => setAnchorEl(null)}
                          />
                        </div>
                        <Divider style={{ margin: "4px 0 8px" }} />
                        <div> {item?.excludedRegion || "N/A"}</div>
                      </div>
                    </Popover>
                  </>
                )}
              </div>
            </div>

            <div className="line"></div>

            {_.isArray(item?.prominentBenefits) ? (
              <>
                {(item?.prominentBenefits || []).map((benefit, index) => (
                  <div key={index} className="data-row">
                    <div>{benefit?.label}</div>
                    {index === 6 ? (
                      <div className="row">
                        <div
                          ref={divProminentRef}
                          title={benefit?.value || ""}
                          className="value-3"
                          dangerouslySetInnerHTML={{
                            __html: benefit?.value || "N/A",
                          }}
                        />

                        {divProminentRef?.current?.offsetWidth > 130 && (
                          <>
                            <img
                              src={infoIcon}
                              alt="infoIcon"
                              onClick={(e) =>
                                setAnchorElProminent(e.currentTarget)
                              }
                            />
                            <Popover
                              id={idProminent}
                              open={openProminent}
                              anchorEl={anchorElProminent}
                              onClose={() => setAnchorElProminent(null)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <div
                                style={{
                                  padding: 10,
                                  fontSize: 14,
                                  maxWidth: "200px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: 500,
                                      color: "#646D69",
                                    }}
                                  >
                                    Khác
                                  </div>
                                  <CloseIcon
                                    style={{ width: 18, color: "#646D69" }}
                                    onClick={() => setAnchorElProminent(null)}
                                  />
                                </div>
                                <Divider style={{ margin: "4px 0 8px" }} />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: benefit?.value || "",
                                  }}
                                />
                              </div>
                            </Popover>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="value-1">
                        {formatMoney(benefit?.value) || 0} VNĐ
                      </div>
                    )}
                  </div>
                ))}
                <div className="data-row">
                  <div style={{ whiteSpace: "nowrap" }}>Bảo lãnh viện phí</div>
                  <div className="value-2">
                    {(item?.hospitalGuarantee || [])
                      .map((key) => hospitalGuaranteeName(key))
                      .join(", ") || "N/A"}
                  </div>
                </div>
              </>
            ) : (
              // old prominentBenefits is object
              <>
                <div className="data-row">
                  <div>Tử vong, thương tật vĩnh viễn do tai nạn:</div>
                  <div className="value-1">
                    {formatMoney(
                      item?.prominentBenefits?.tu_vong_thuong_tat_tai_nan
                    ) || 0}{" "}
                    VNĐ
                  </div>
                </div>
                <div className="data-row">
                  <div>Tử vong, thương tật vĩnh viễn do bệnh tật:</div>
                  <div className="value-1">
                    {formatMoney(
                      item?.prominentBenefits?.tu_vong_thuong_tat_benh_thai_san
                    ) || 0}{" "}
                    VNĐ
                  </div>
                </div>
                <div className="data-row">
                  <div>Nội trú/ năm:</div>
                  <div className="value-1">
                    {formatMoney(item?.prominentBenefits?.noi_tru_tren_nam) ||
                      0}{" "}
                    VNĐ
                  </div>
                </div>
                <div className="data-row">
                  <div>• Viện phí/ ngày</div>
                  <div className="value-1">
                    {formatMoney(item?.prominentBenefits?.vien_phi_tren_ngay) ||
                      0}{" "}
                    VNĐ
                  </div>
                </div>
                <div className="data-row">
                  <div>Ngoại trú/ năm:</div>
                  <div className="value-1">
                    {formatMoney(item?.prominentBenefits?.ngoai_tru_tren_nam) ||
                      0}{" "}
                    VNĐ
                  </div>
                </div>
                <div className="data-row">
                  <div>• Giới hạn/ lần khám</div>
                  <div className="value-1">
                    {formatMoney(
                      item?.prominentBenefits?.gioi_han_tren_lan_kham
                    ) || 0}{" "}
                    VNĐ
                  </div>
                </div>
                <div className="data-row">
                  <div style={{ whiteSpace: "nowrap" }}>Bảo lãnh viện phí</div>
                  <div className="value-2">
                    {(item?.hospitalGuarantee || [])
                      .map((key) => hospitalGuaranteeName(key))
                      .join(", ") || "N/A"}
                  </div>
                </div>
                <div className="data-row">
                  <div>Khác</div>
                  <div className="row">
                    <div
                      ref={divProminentRef}
                      title={item?.prominentBenefits?.khac || ""}
                      className="value-3"
                    >
                      {item?.prominentBenefits?.khac || "N/A"}
                    </div>
                    {divProminentRef?.current?.offsetWidth > 130 && (
                      <>
                        <img
                          src={infoIcon}
                          alt="infoIcon"
                          onClick={(e) => setAnchorElProminent(e.currentTarget)}
                        />
                        <Popover
                          id={idProminent}
                          open={openProminent}
                          anchorEl={anchorElProminent}
                          onClose={() => setAnchorElProminent(null)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <div
                            style={{
                              padding: 10,
                              fontSize: 14,
                              maxWidth: "200px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{ fontWeight: 500, color: "#646D69" }}
                              >
                                Khác
                              </div>
                              <CloseIcon
                                style={{ width: 18, color: "#646D69" }}
                                onClick={() => setAnchorElProminent(null)}
                              />
                            </div>
                            <Divider style={{ margin: "4px 0 8px" }} />
                            <div>{item?.prominentBenefits?.khac || "N/A"}</div>
                          </div>
                        </Popover>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <div className="line"></div>
          <div className="center">
            <Button
              variant="contained"
              className="btn"
              onClick={() => {
                dispatch({ type: "setSelectItem", item });
                dispatch({
                  type: "getDetail",
                  benefitsDetailUidList: item?.benefitsDetailUidList,
                  infoFilter: item?.infoFilter,
                  gender: Base64.encode(item?.gender || ""),
                  openOrder: true,
                });
                // dispatch("openOrderDialog");
                dispatch({ type: "addCustomerViews", item });
                createEventGtag("health_home_buy_now_btn");
              }}
            >
              <img src={cart} alt="cart" className="btn-icon" />
              Mua ngay
            </Button>
            <div
              className="underline"
              onClick={() => {
                dispatch({ type: "checkInfoToViewDetail", item });
                dispatch({ type: "addCustomerViews", item });
                createEventGtag("health_home_view_detail_btn");
              }}
            >
              Xem chi tiết
            </div>
          </div>
        </div>
      </div>
      {item?.openShare && (
        <div style={{ position: "relative" }}>
          <div className="menu-share">
            <MenuItem
              onClick={() => dispatch({ type: "openReferalInfo", item })}
            >
              <img src={share} alt="share" className="share-icon" />
              Chia sẻ
            </MenuItem>
            {/* <MenuItem onClick={() => dispatch({ type: "openErrReport", item })}>
              <img src={reportErr} alt="report" className="share-icon" />
              Báo lỗi
            </MenuItem> */}
          </div>
        </div>
      )}
      <Backdrop
        style={{
          zIndex: 9,
          color: "#fff",
          position: "absolute",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
        open={item?.openShare}
        onClick={() => dispatch("closeShare")}
      />
    </Grid>
  );
};
