import styled from "@emotion/styled";
import moment from "moment";

export const initialState = {
  providers: [],
  ageArr: [],
  dataSearch: {},
  data: [],
  selectedItem: {},
  currentStep: 1,
  openDetail: false,
  openOrder: false,
  openOTPVerify: false,
  openSuccess: false,
  detailData: {},
  detailEdit: {},
  errorEdit: [],
  dataStep1: {},
  dataStep2: {},
  dataStep3: {},
  dataOTP: {},
  dataBooking: {},
  dataInfo: {},
  dataReferal: {},
  dataErrReport: {},
  errors: [],
  clear: false,
  loading: false,
  loadDetail: false,
  expanding: false,
  page: { pageSize: 8, pageNumber: 1, total: 0 },
};

export const genders = [
  { id: 1, label: "Nam" },
  { id: 2, label: "Nữ" },
];

export const orderByArr = [
  { id: 1, label: "Tất cả" },
  { id: 2, label: "Giá thấp đến cao" },
  { id: 3, label: "Giá cao đến thấp" },
  { id: 4, label: "Bán chạy nhất" },
  { id: 5, label: "Xem nhiều nhất" },
  { id: 6, label: "Sản phẩm mới" },
];

export const searchFees = [
  { id: 1, label: "Tất cả" },
  { id: 2, label: "Đến 3 triệu" },
  { id: 3, label: "Từ 3 đến 8 triệu" },
  { id: 4, label: "Trên 8 triệu" },
];

export const relationship = [
  { checked: true, name: "Con hợp pháp", code: "con_hop_phap" },
  { checked: true, name: "Anh chị em ruột", code: "anh_chi_em_ruot" },
  { checked: true, name: "Vợ/chồng", code: "vo_chong" },
  {
    checked: true,
    name: "Người khác có quan hệ nuôi dưỡng, cấp dưỡng",
    code: "nguoi_khac_co_moi_quan_he_nuoi_duong_cap_duong",
  },
  {
    checked: true,
    name: "Người sử dụng lao động",
    code: "nguoi_su_dung_lao_dong",
  },
];

export const hospitalGuaranteeName = (key) => {
  switch (key) {
    case "inpatient":
      return "Nội trú";
    case "outpatient":
      return "Ngoại trú";
    case "meternity":
      return "Thai sản";
    case "accident":
      return "Tai nạn";
    case "tooth":
      return "Răng";
    default:
      return "";
  }
};

export const renderCategoryName = (key) => {
  switch (key) {
    case "noi_tru":
      return "Nội trú";
    case "ngoai_tru":
      return "Ngoại trú";
    case "thai_san":
      return "Thai sản";
    case "tu_vong_thuong_tat_tai_nan":
      return "Tử vong, thương tật vĩnh viễn do tai nạn";
    case "tu_vong_thuong_tat_benh_tat_thai_san":
      return "Tử vong, thương tật vĩnh viễn do bệnh tật, thai sản";
    case "rang":
      return "Răng";
    default:
      return "";
  }
};

export const renderGCNFile = (providerId, productTitle) => {
  switch (providerId) {
    case 3:
    case 27:
      return "/templete-bhsk/GCN-PVI.docx";
    case 8:
      return "/templete-bhsk/GCN-MIC CARE.docx";
    case 16:
      if ((productTitle || "").toLowerCase().includes("tâm bình")) {
        return "/templete-bhsk/GCN-BV-TamBinh.docx";
      }
      if ((productTitle || "").toLowerCase().includes("an tâm")) {
        return "/templete-bhsk/GCN-BV-AnTamVienPhi.docx";
      }
      if ((productTitle || "").toLowerCase().includes("intercare")) {
        return "/templete-bhsk/GCN-BV-Intercare.docx";
      }
      if ((productTitle || "").toLowerCase().includes("an gia")) {
        return "/templete-bhsk/GCN-BV-AnGia.docx";
      }
    case 18:
      return "/templete-bhsk/GCN-BIC.docx";
    case 21:
      if ((productTitle || "").toLowerCase().includes("ffc")) {
        return "/templete-bhsk/GCN-Fubon.docx";
      }
    default:
      return "/templete-bhsk/GCN-BIC.docx";
  }
};

export const renderPaymentDateGCN = (productTitle, beginDate) => {
  const title = (productTitle || "").toLowerCase();
  if (title.includes("intercare") || title.includes("an gia")) {
    return moment(beginDate?.toDate()).subtract(1, "day").format("DD/MM/YYYY");
  }
  if (title.includes("an tâm") || title.includes("tâm bình")) {
    return moment(beginDate?.toDate()).subtract(2, "day").format("DD/MM/YYYY");
  }
};

export const renderProductName = (productId) => {
  switch (productId) {
    case 8:
      return "Sức Khoẻ";
    default:
      return "";
  }
};

export const BpIcon = styled("span")(() => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  backgroundColor: "#fff",
  border: "1px solid #1A427D",
}));

export const BpCheckedIcon = styled(BpIcon)({
  color: "#fff",
  backgroundColor: "#1A427D",

  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },

  "input:hover ~ &": {
    backgroundColor: "#1A427D",
  },
});
