import { Paper, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import useReducer from "../../library/hooks/useReducer";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { getParamUrl } from "../extra/utils";
import productReducer from "../reducers/productReducer";
import { useStyles } from "../styles/productStyles";
import Home from "./Home";

import "react-toastify/dist/ReactToastify.css";
import top from "../assets/icons/scroll-top.svg";

const initialState = {
  products: [],
  banners: [],
  productSelected: {},
  openDrawer: false,
  isVisibleBtn: false,
  scrollToBenefit: false,
};

function Product() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  // const platform = getParamUrl("platform");

  const [state, dispatch] = useReducer({
    reducer: productReducer,
    name: "productReducer",
    initialState,
  });

  useEffect(() => {
    dispatch("initial");

    if (window.gtag) {
      const hostSource =
        window.location.hostname === "globalcare.vn" ? "" : "test_";
      const typeCode = getParamUrl("code")
        ? `${hostSource}${getParamUrl("code")}_`
        : `${hostSource}_`;
      window.gtag("event", `${typeCode}home`);

      if (getParamUrl("referalCode")) {
        window.gtag(
          "event",
          `${typeCode}home_referal_code_${getParamUrl("referalCode")}`
        );
      }

      if (getParamUrl("utm_source")) {
        window.gtag(
          "event",
          `${typeCode}home_utm_source_${getParamUrl("utm_source")}`
        );
      }
    }
  }, []);

  const toggleVisibility = () => {
    if (window.scrollY > window.innerHeight * 3) {
      dispatch("showScrollBtn");
    } else {
      dispatch("hideScrollBtn");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const { isVisibleBtn } = state;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={classes.body}>
      <div className={classes.page}>
        <Paper
          //   sx={{ width: "100%", maxWidth: { md: "1536px" } }}
          elevation={2}
          sx={{ width: "100%", boxShadow: "none" }}
        >
          <Navbar isMobile={isMobile} dispatch={dispatch} state={state} />
          <Router>
            <Routes>
              <Route
                path="/*"
                element={<Navigate replace to="/products-hub?code=health" />}
              />
              <Route
                path="/products-hub*"
                element={
                  <Home isMobile={isMobile} state={state} dispatch={dispatch} />
                }
              />
            </Routes>
          </Router>
          <Footer isMobile={isMobile} dispatch={dispatch} />
        </Paper>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          style={{ width: "fit-content", fontSize: 14 }}
        />
      </div>

      {isVisibleBtn && (
        <div className={classes.scrollTopBtn}>
          <img src={top} alt="top" onClick={() => scrollToTop()} />
        </div>
      )}
    </div>
  );
}

export default Product;
