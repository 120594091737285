import { getBannerImages, getProductList } from "../../controller/bhskApi";
import bhngheo from "../assets/icons/bh-bhngheo.svg";
import dquy from "../assets/icons/bh-dquy.svg";
import dulich from "../assets/icons/bh-dulich.svg";
import ntho from "../assets/icons/bh-ntho.svg";
import ntnhan from "../assets/icons/bh-ntnhan.svg";
import smang from "../assets/icons/bh-sinhmang.svg";
import skhoe from "../assets/icons/bh-skhoe.svg";
import tncnhan from "../assets/icons/bh-tncnhan.svg";
import uthu from "../assets/icons/bh-uthu.svg";

import whiteBHNgheo from "../assets/icons/white-bhngheo.svg";
import whiteDquy from "../assets/icons/white-dquy.svg";
import whiteDulich from "../assets/icons/white-dulich.svg";
import whiteNTho from "../assets/icons/white-ntho.svg";
import whiteNTNhan from "../assets/icons/white-ntnhan.svg";
import whiteSkhoe from "../assets/icons/white-skhoe.svg";
import whiteSmang from "../assets/icons/white-smang.svg";
import whiteTNCNhan from "../assets/icons/white-tncnhan.svg";
import whiteUThu from "../assets/icons/white-uthu.svg";

const renderProductItem = (item) => {
  switch (item.id) {
    case 8:
      return {
        ...item,
        img: skhoe,
        whiteImg: whiteSkhoe,
        link: "health",
      };
    case 6:
      return {
        ...item,
        img: bhngheo,
        whiteImg: whiteBHNgheo,
        link: "bhngheo",
      };
    case 2:
      return {
        ...item,
        img: dquy,
        whiteImg: whiteDquy,
        link: "dotquy",
      };
    case 23:
      return {
        ...item,
        img: dulich,
        whiteImg: whiteDulich,
        link: "dulich",
      };
    case 7:
      return {
        ...item,
        img: ntnhan,
        whiteImg: whiteNTNhan,
        link: "ntnhan",
      };
    case 5:
      return {
        ...item,
        img: tncnhan,
        whiteImg: whiteTNCNhan,
        link: "tainan",
      };
    case 42:
      return {
        ...item,
        img: smang,
        whiteImg: whiteSmang,
        link: "sinhmang",
      };
    case 43:
      return {
        ...item,
        img: ntho,
        whiteImg: whiteNTho,
        link: "nhantho",
      };
    case 44:
      return {
        ...item,
        img: uthu,
        whiteImg: whiteUThu,
        link: "ungthu",
      };
    default:
      break;
  }
};

export default function productReducer({ state, dispatch, action }) {
  const cases = {
    initial: async () => {
      const res = {};
      const [resApi, resApiBanner] = await Promise.all([
        getProductList(),
        getBannerImages(),
      ]);

      res.banners = (resApiBanner?.result?.result || []).sort((a, b) => {
        return a.created_at - b.created_at;
      });
      res.products = (resApi?.result || [])
        .sort((a, b) => {
          if (a.id === 8) return -1;
          if (b.id === 8) return 1;
          return a.id - b.id;
        })
        .map((item) => {
          return renderProductItem(item);
        });
      //selected default BHSKhoe
      res.productSelected =
        (resApi?.result || []).find((i) => i.id === 8) || {};

      return res;
    },

    selectedProduct: () => {
      const { products } = state;
      const productSelected = products.find((i) => i.id === action.id);
      return { productSelected };
    },

    selectNav: () => {
      if (action.id === 1) {
        window.location.reload();
      }
      if (action.id === 2) {
        const hostname = window.location.hostname;
        if (hostname.includes("dev")) {
          window.open("https://tracuu-dev.globalcare.vn", "_blank");
        } else {
          window.open("https://tracuu.globalcare.vn", "_blank");
        }
      }
    },

    setOpenDrawer: () => ({ openDrawer: !state.openDrawer }),
    showScrollBtn: () => ({ isVisibleBtn: true }),
    hideScrollBtn: () => ({ isVisibleBtn: false }),
    onChangeScroll: () => ({ scrollToBenefit: action.value }),
  };
  return cases[action?.type];
}
